import React from 'react'
import Layout from '../components/Layout'
import styled from "styled-components"

const FourOFour = styled.div`
  margin: 100px;
  color: #888888;
  text-align: center;
  margin-bottom: 500px;
  
  h1 {
    font-size: 80px;
    text-transform: uppercase;
  }
  
  p {
    font-size: 24px;
  }
  
  img {
    margin: 40px;
    border-radius: 4px;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <FourOFour>
      <h1>Stránka nenalezena</h1>
      <p>Tato stránka neexistuje.</p>
      <p>Doporučujeme přejít na <a href={"/"}>hlavní stránku TunaSec</a>.</p>
      <img style={{borderRadius: "6px"}} alt={"404"} src={"/img/layout/404-page.jpg"}/>
    </FourOFour>
  </Layout>
)

export default NotFoundPage
